<template>
 <div class="auth">
    <div class="auth__container">
      <img src="/logo.jpg" alt="Мониторинга работоспособности вашего сайта" class="logo">
        <h3 class="auth__text">мониторинг состояния сайта 24/7</h3>
        <a v-bind:href="API_CODE_LINK">

            <img src="https://yastatic.net/s3/doc-binary/freeze/ru/id/e0b865fcda6c52cdbaa9969a5a5472ae23c18252.svg" alt="иконка яндекса" class="auth__logo" >
        </a>
    </div>
 </div>
</template>
<script>
import { mapActions,mapGetters,mapMutations } from 'vuex';
export default {
    data() {
        return {}
    },
    methods: {
        ...mapActions([]),
        ...mapMutations(['SET_ACCESS_TOKEN']),
        getGetParams(key) {
            var p = window.location.search;
            p = p.match(new RegExp(key + '=([^&=]+)'));
            return p ? p[1] : false;
        },
        getToken(code) {

            const dataToSend={
                grant_type: 'authorization_code',
                code: code,
                client_id: this.CLIENT_ID,
                client_secret: this.CLIENT_SECRET
            }
            let paramsString= Object.keys(dataToSend).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(dataToSend[key]);
            }).join('&');

            fetch('https://oauth.yandex.ru/token',{
                method : 'POST',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                },
                body: paramsString
            })
            .then(response => response.json())
            .then(result=>{
                this.SET_ACCESS_TOKEN(result.access_token)
                localStorage.setItem('access_token',result.access_token)
                this.$router.push('/Lk')

            })
        }

    },
    computed: {
        ...mapGetters([
        "API_CODE_LINK","CLIENT_ID","CLIENT_SECRET","ACCESS_TOKEN"
        ])
    },
    mounted() {

        //
        if (localStorage.getItem('access_token')==null) {
            if (this.getGetParams('code')) {


                this.getToken(this.getGetParams('code'))
            }
        } else {
                 this.$router.push('/Lk')
        }
    }
}
</script>

<style scoped>
 .auth {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
 }
 .auth__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    padding: 20px 30px

 }
 .logo {
  max-width: 88px;
 }

 .auth__text {
  margin-top: 15px;
 }
 .auth__logo {
    max-width: 252px;
    margin-top: 20px;
 }

</style>
