import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library, Library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import  {faPlus, faListUl, faPlug, faPlugCircleXmark, faRightFromBracket, faRobot, faRotate, faUserSecret, faSquarePlus, faCircleXmark, faTriangleExclamation, faChartLine} from '@fortawesome/free-solid-svg-icons'
import { faTelegram, faYandex } from '@fortawesome/free-brands-svg-icons'
import { faClone,faRectangleList } from '@fortawesome/free-regular-svg-icons'

library.add(faUserSecret,faYandex,faRightFromBracket, faListUl, faTelegram,faClone,faRobot, faPlugCircleXmark,faPlug,faRotate,
  faPlus,faSquarePlus, faCircleXmark,faTriangleExclamation,faRectangleList,faChartLine
  )

createApp(App)
.component('font-awesome-icon',FontAwesomeIcon)
.use(store)
.use(router)
.mount('#app')
