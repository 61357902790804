<template>

  <div class="detail__container">

    <div v-show="monthData">
      <span class="day_header">{{ day }}</span>
      <span @click="show_log=!show_log; show_graph = !show_graph" class="show_log" :class="{ show_log_active: show_log } "><font-awesome-icon icon="fa-regular fa-rectangle-list" /> log</span>
      <span @click="show_log=!show_log; show_graph = !show_graph" class="show_log" :class="{ show_log_active: show_graph }"><font-awesome-icon icon="fa-solid fa-chart-line" /> ping</span>
      <div class="chart_block" v-show="show_graph"></div>
      <ul class="log__ul" v-show="show_log">
        <li  v-for="item in monthData" :key="item.timestamp" class="log__li"
        :class="{ error: item.errCode==0 || item.errCode >=400, warning: item.errCode>=300 && item.errCode<=399 }"
        >
          <span class="date">{{item.timestamp}} </span> |
          <span class="ping"> ping: {{ item.time }}ms</span> |
          <span class="message">HTTP resp: {{ item.errCode }} {{ item.errMsg }}</span>
        </li>
      </ul>
    </div>
    <div class="withoutData" v-show="!monthData">
      <span class="withoutData_pic">  ¯\_(ツ)_/¯ </span>
      <br>
      нет данных для отображения
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Chart from 'chart.js/auto'
import { mapGetters, mapMutations} from 'vuex'

export default {
  data() {
    return {
      monthData: false,
      day: '',
      show_log: false,
      show_graph: true,
      chart: ''

    }
  },
  computed: {
    ...mapGetters(['USER','DAY','CURRENT_SITE'])
  },
  mounted() {
    this.day = moment(this.DAY).format("DD MMMM YYYY");

  },
  methods: {
    ...mapMutations(['SET_MESSAGE']),

    drawGraph() {
      //удаляем старый
      document.querySelector('.chart_block').innerHTML = '';
      document.querySelector('.chart_block').innerHTML='<canvas class="chart"></canvas>'
      //готовим данные
      let graphLabel=[]
      let graphData = []
      if(this.monthData) {
        this.monthData.forEach(item=>{
        graphLabel.push(item.timestamp)
        graphData.push(item.time)
      })
      }


      let ctx=document.querySelector('.chart')
      let chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: graphLabel,
          datasets: [{
            label: 'ms',
            data: graphData,
            borderColor: '#2a2a2a',
            borderWidth: 1,
            pointRadius: 1,
            pointHoverRadius: 1,
            pointHitRadius: 1,
            pointBorderWidth: 1,
          }]
        },
        options: {
          legend: {
            display: true
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });


    },

    getDayData() {

      const dayToResp= this.DAY.getFullYear() + '-' + (this.DAY.getMonth() + 1) + '-' + this.DAY.getDate()
      const url= 'https://uptime-vbi.ru/controller/getDayData.php?day=' + dayToResp + '&token=' + this.USER.token + '&siteId=' + this.CURRENT_SITE.id

      fetch(url)
      .then(response => response.json())
        .then(result => {
          if(result.result==true) {
            this.monthData=result.data;
            //и переформатируем красиво дату
            this.monthData.forEach(item => {
              item.timestamp=moment(item.timestamp).format('LT')
            });
            this.drawGraph()

          } else {
            //this.SET_MESSAGE({ show: true, text: result.message })
            this.monthData=false
            this.drawGraph()
          }
        })
    }
  },
  watch: {
    CURRENT_SITE: function() {
      this.getDayData();
    },
    DAY: function() {
      this.day = moment(this.DAY).format("DD MMMM YYYY");
      this.getDayData();

    }

  }

}
</script>

<style scoped>
.day_header {
  text-align: left;
  background-color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 7px;
  border-radius: 3px;
}
.detail__container {
  text-align: left;
  margin-top: 15px;
}
.log__ul {
  list-style: none;
  text-align: left;
  margin: 10px;
  padding: 10px;
  background-color: #2a2a2a;
  color: #e5eaf9;
  display: block;
  overflow-y: scroll;
  max-height: 40vh;
  font-size: 14px;
}
.log__li {
  padding: 2px;
}
.log__li:hover {
  background: #fff;
  color:#2a2a2a
}
.show_log {
  margin-left: 10px;
  text-align: left;
  background-color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 7px;
  border-radius: 3px;
}
.show_log:hover {
  cursor: pointer;
  color:#fff;
  background-color: #2a2a2a;
}
.show_log_active {
  color:#fff;
  background-color: #2a2a2a;
}
.warning {
    background-color: #ffd300;
    color: #2a2a2a;
}
.chart_block {
    max-height: 40vh;
    width: 100%;
}
.chart {
  width: 100%
}
.withoutData {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.withoutData_pic {
    font-size: 30px;
}
</style>
