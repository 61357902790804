import { createStore } from 'vuex'

export default createStore({
  state: {
    clientId: '9671f2ae90cc4f6fbeafdda128b9af3a',
    clientSecret: 'f537abaa8c5740c98e557fae72f81d71',
    //redirectUri: 'http://localhost:8080/',
    redirectUri: 'https://uptime-vbi.ru',
    access_token: '',
    user: {
      name: '',
      ya_id: '',
      telegram: 0,
      token: '',
      avatar: ''
    },
    sitesList: [],
    message: {
      show: false,
      text: ''
    },
    currentSite: false,
    day: ''

  },
  getters: {
    DAY(state) {
      return state.day
    },
    API_CODE_LINK(state) {
      return 'https://oauth.yandex.ru/authorize?response_type=code&client_id='+state.clientId+'&redirect_uri='+state.redirectUri
    },
    CLIENT_ID(state) {
      return state.clientId
    },
    CLIENT_SECRET(state) {
      return state.clientSecret
    },
    ACCESS_TOKEN(state) {
      return state.access_token
    },
    USER(state) {
      return state.user
    },
    SITES_LIST(state) {
      return state.sitesList
    },
    MESSAGE(state) {
      return state.message
    },
    CURRENT_SITE(state) {
      return state.currentSite
    }
  },
  mutations: {
    SET_DAY: (state,day)=>{
      state.day=day
    },
    SET_MESSAGE: (state,msg)=>{
      state.message=msg
    },
    SET_ACCESS_TOKEN:(state,token) => {
      state.access_token=token
    },
    SET_USER: (state,user) => {
      state.user=user
    },
    SET_SITES_LIST: (state,sitesList) =>{
      state.sitesList=sitesList
    },
    SET_CURRENT_SITE: (state, currentSite) => {
      state.currentSite=currentSite
    }
  },
  actions: {
    GET_SITES_LIST({commit},token) {
      fetch('https://uptime-vbi.ru/controller/getSitesList.php?token=' + token)
        .then(response => response.json())
        .then(result => {
          if (result.result === true) {
            commit('SET_SITES_LIST',result.sitesList)
          } else {
           // console.log(result.message)
          }
        })
        return
    },
    UPDATE_CUR_SITE_STATUS({commit},param) {
      let link='https://uptime-vbi.ru/controller/updCurSiteStatus.php?token=' + param.token+'&&siteId='+param.siteId
      fetch(link)
        .then(response=>response.json())
        .then(result=>{
          if (result.result==='true') {
            let updCurSite={}
              updCurSite.id=result.id,
              updCurSite.siteLink=result.siteLink
              updCurSite.status=result.status,
              updCurSite.sslDateTo=result.sslDateTo,
              updCurSite.dnsDateTo=result.dnsDateTo,
              updCurSite.ping=result.ping
            commit('SET_CURRENT_SITE',updCurSite)
          } else {
           // console.log(result.result)
           // console.log(link)

          }
        })
    }
  },
  modules: {
  }
})
