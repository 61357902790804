<template>
  <div class="lk">
    <SideBar/>
    <Detail/>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'
import Detail from './Detail.vue';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {

        }
    },
    computed: {
      ...mapGetters(['USER'])
    },
    components: {
        SideBar,Detail
    },
    methods: {

    },
    mounted() {
      if(localStorage.getItem('access_token')==null) {
        this.$router.push('/')
      }
    },
    watch: {
      USER: function () {
      }
    }

}
</script>

<style>
.lk {
  min-height: 100vh;
  display:flex;

}
</style>
