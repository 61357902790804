<template>
  <div id="app" class="App">
    <router-view/>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2a2a2a;
  background: #e5eaf9;
  padding: 0;
  margin: 0;
}
h3 {
  color:#2a2a2a;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
</style>
