<template>
  <div class="telegramBlock">
    <div class="header_block">
      <div>
        <span class="icon_tg"><font-awesome-icon icon="fa-brands fa-telegram" /></span> Telegram

      </div>
      <div>
        <span class="icon_success" v-show="USER.telegram!='0'">
          <font-awesome-icon icon="fa-solid fa-plug" />
        </span>
        <span class="icon_error" v-show="USER.telegram=='0'">
          <font-awesome-icon icon="fa-solid fa-plug-circle-xmark" />
        </span>
        <span class="check_icon"><font-awesome-icon icon="fa-solid fa-rotate"  @click="checkTelegram(USER.token)"/></span>
      </div>
    </div>
    <div class="not_active" v-show="USER.telegram=='0'">
      для подключения пришлите<br>
      это число:
      <span class="code">
        {{ USER.ya_id }}
      </span>
      <span class="copy"  @mousedown="copyText(USER.ya_id )" @mouseup="returnCopyColor()">
        &nbsp;<font-awesome-icon icon="fa-regular fa-clone"/>
      </span>
      <br>
      <a href="https://t.me/uptimeVBI_bot" target="blank" class="robots_link">нашему роботу <font-awesome-icon icon="fa-solid fa-robot" /></a>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapMutations } from 'vuex';

export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['USER'])
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    copyText(ya_id) {
      window.navigator.clipboard.writeText(ya_id).then(()=>{
        let copyItem=document.querySelector('.copy');
        copyItem.classList.add('copied');
      })
    },
    returnCopyColor() {
      let copyItem=document.querySelector('.copy');
      setTimeout(()=>{copyItem.classList.remove('copied')},500)
    },
    checkTelegram(token) {
      fetch('https://uptime-vbi.ru/controller/checkTelegram.php?token=' + token)
        .then(response => response.json())
        .then(result => {
          let user = {
            name: result.user.name,
            ya_id: result.user.ya_id,
            telegram: result.user.telegram,
            token: result.user.token,
            avatar: this.USER.avatar
          }
          this.SET_USER(user)
        })
    }

  }

}
</script>

<style scoped>
.robots_link {
  font-weight: 600;
  color: #fff;
}
.telegramBlock {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.header_block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.not_active {
  font-size: 14px;
  line-height: 18px;
  text-align: start;
  background: #2a2a2a;
  color: #fff;
  padding: 6px;
  margin-top: 10px;
  border-radius: 5px;
}
.code {
  font-size: 15px;
  font-weight: 700;
}
.copy:hover {
  cursor: pointer;
}
.icon_tg {
  font-size: 20px;
  color: #000;
}
.icon_success,.copied {
  color: #006200;
  font-size: 16px;
}
.icon_error {
  color:#a52a2a;
}
.check_icon{
  margin-left:5px;
}
.check_icon:hover {
  cursor:pointer;
}

</style>
