<template>
  <div  class="detailContainer">
    <div class="withData" v-show="currentSite">
      <DetailSiteInfo />
      <DetailCalendar />
      <DetailLog />
    </div>
    <div class="withoutData" v-show="!currentSite">
      <span class="withoutData_pic">  ¯\_(ツ)_/¯ </span>
      <br>
      нет данных для отображения
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DetailSiteInfo from './DetailSiteInfo.vue'
import DetailCalendar from './DetailCalendar.vue'
import DetailLog from './DetailLog.vue'

export default {
  data() {
    return {
      currentSite: false
    }
  },
  components: {
      DetailSiteInfo, DetailCalendar, DetailLog
    },
  computed: {
    ...mapGetters(['CURRENT_SITE','USER'])
  },
  mounted() {

  },
   methods: {

  },
  watch: {
    CURRENT_SITE: function () {
      this.currentSite=this.CURRENT_SITE;
    //  console.log('сработал апдейт')
    }
  }


}
</script>

<style scoped>
.detailContainer {
  width: 100%;
  min-height: calc(100vh - 30px);
  padding: 15px 10px;
}
.withoutData {
    min-height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.withoutData_pic {
    font-size: 50px;
}
</style>
