<template>
  <div>
    <div class="box" v-show="MESSAGE.show==true">
      <div class="message"  @click="hideMessage()">
        {{ MESSAGE.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapMutations } from 'vuex';

export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['MESSAGE'])
  },
  methods:{
    ...mapMutations(['SET_MESSAGE']),
    hideMessage() {
        this.SET_MESSAGE({ show: false, text: '' })
    }
  },
  watch: {
    MESSAGE: function() {
      if (this.MESSAGE.show == true) {
        setTimeout(()=>{
          this.SET_MESSAGE({ show: false, text: '' })
        },1700)
      }
    }
  }

}


</script>

<style>
.box {
  position: absolute;
  top: 15px;
  right: -210px;
  max-width: 200px;
  border-radius: 3px;
  text-align: left;
  background: #fff;
}
.message {
  padding: 7px;
}
.message:hover {
  cursor: pointer;
}
</style>
