<template>
  <div class="sidebar">

    <div class="user_info">
      <img :src="USER.avatar" alt="avatar" class="avatar_img">
      <span class="user_name">{{ USER.name }}</span>
      <span class="exit"><font-awesome-icon icon="fa-solid fa-right-from-bracket" @click="logout()" /></span>
    </div>
    <TelegramSettings/>
    <SitesList/>
    <MessageBox/>
  </div>

</template>

<script>
import router from "@/router";
import SitesList from "@/components/SitesList.vue"
import TelegramSettings from "./TelegramSettings.vue";
import MessageBox from "./MessageBox.vue";
import { mapMutations,  mapGetters } from 'vuex';

export default {
    data() {
        return {

        }
    },
    components: {
      SitesList, TelegramSettings, MessageBox
    },
    methods: {
        ...mapMutations(["SET_USER"]),
        logout() {
            let user={
              name: '',
              ya_id: '',
              telegram: 0,
              token: ''
            }
          this.SET_USER(user)
            localStorage.removeItem('access_token')
            router.push('/')
        },
        afterLogin(yaId,yaLogin) {
            fetch('https://uptime-vbi.ru/controller/afterLogin.php?yaId=' + yaId + '&yaLogin=' + yaLogin
            ).then(response => response.json())
                .then(result => {
                    if(result.result==false) {
                        //console.log(result.message)
                    }
                    else if(result.message == 'new_user_added') { this.afterLogin(yaId, yaLogin) }
                    else {
                      let user={
                        name: result.user.name,
                        ya_id: result.user.ya_id,
                        telegram: result.user.telegram,
                        token: result.user.token,
                        avatar: this.avatar
                      }
                     // console.log(user);
                      this.SET_USER(user)
                    }
                }
            )
        }
    },
    mounted() {

        fetch('https://login.yandex.ru/info?format=json&with_openid_identity=1&oauth_token='+localStorage.getItem('access_token'))
            .then(response => response.json())
            .then(result=>{
                this.login=result.login
                this.id=result.id,
                  this.avatar = 'https://avatars.yandex.net/get-yapic/' + result.default_avatar_id +'/islands-middle'
                this.afterLogin(result.id, result.login)
            })
    },
  computed: {
    ...mapGetters(["USER"])
  }
}
</script>

<style scoped>
.exit:hover {
  cursor:pointer
}
.avatar_img {
  border-radius: 50%;
  border: 2px solid #2a2a2a;
}
.user_name::first-letter {
  color:#a52a2a;
  font-weight: 600;
}
.user_info {
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0000000f;
  padding: 0 0 10px 0;
}
.sidebar{
  position: relative;
  padding: 15px 10px;
  border-right: 1px solid #0000000f;
}
</style>
