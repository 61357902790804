<template>
  <div class="header">
    <span class="detail__sitename">{{ currentSite.siteLink }}</span>
    <span class="status_block">
      <span class="status http" >HTTP: {{ currentSite.status }}</span>
      <span class="status ping"> PING: {{ currentSite.ping }}</span>
      <span class="status dns">DOMAIN_DATE_TO: {{ currentSite.dnsDateToShow }}</span>
      <span class="status ssl">SSL_DATE_TO: {{ currentSite.sslDateToShow }}</span>

    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'


export default {
  data() {
    return {
      currentSite: false
    }
  },
  computed: {
    ...mapGetters(['CURRENT_SITE'])
  },
  mounted() {
   moment.locale('ru');
  },
  methods: {
    getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);

      const oneDay = 1000 * 60 * 60 * 24;

      const diffInTime = date2.getTime() - date1.getTime();

      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    },
    setBlockStyle(block,style){
      block.classList.remove('success')
      block.classList.remove('error')
      block.classList.remove('warning')
      block.classList.add(style)
    }
  },
  watch: {
    CURRENT_SITE: function() {
      if(this.CURRENT_SITE!=false){
        this.currentSite={}
        this.currentSite.siteLink=this.CURRENT_SITE.siteLink
        this.currentSite.status = this.CURRENT_SITE.status
        this.currentSite.ping = this.CURRENT_SITE.ping
        if(this.CURRENT_SITE.dnsDateTo=='0000-00-00 00:00:00') {
          this.currentSite.dnsDateTo = new Date('1970-01-01')
        } else {
          this.currentSite.dnsDateTo = new Date(this.CURRENT_SITE.dnsDateTo)
        }
        this.currentSite.dnsDateToShow = moment(this.currentSite.dnsDateTo).format('L')

        if (this.CURRENT_SITE.sslDateTo == '0000-00-00 00:00:00') {
          this.currentSite.sslDateTo = new Date('1970-01-01')
        } else {
          this.currentSite.sslDateTo = new Date(this.CURRENT_SITE.sslDateTo)
        }
        //this.currentSite.dnsDateTo= new Date(this.CURRENT_SITE.dnsDateTo)
        this.currentSite.sslDateToShow = moment(this.currentSite.sslDateTo).format('L')
      }

    },
    currentSite: function(){
      //http
      let httpBlock= document.querySelector('.http');
      let pingBlock = document.querySelector('.ping');
      let dnsBlock = document.querySelector('.dns');
      let sslBlock = document.querySelector('.ssl');
      //http
      if (this.currentSite.status >= 200 && this.currentSite.status <= 299) {
        this.setBlockStyle(httpBlock,'success')
      }
      if (this.currentSite.status >= 300 && this.currentSite.status <= 399) {
        this.setBlockStyle(httpBlock, 'warning')
      }
      if (this.currentSite.status >= 400 || this.currentSite.status==0) {
        this.setBlockStyle(httpBlock, 'error')
      }
      //ping
      if(this.currentSite.ping!==undefined) {
        this.setBlockStyle(pingBlock, 'success')
      } else {
        this.setBlockStyle(pingBlock, 'error')
      }
      //даты
      let currientDate = new Date;

      //dns
      if(this.getNumberOfDays(currientDate, this.currentSite.dnsDateTo)>10) {
        this.setBlockStyle(dnsBlock, 'success')
      }
      if (this.getNumberOfDays(currientDate, this.currentSite.dnsDateTo) > 1
      && this.getNumberOfDays(currientDate, this.currentSite.dnsDateTo)<=9) {
        this.setBlockStyle(dnsBlock, 'warning')
      }
      if (this.getNumberOfDays(currientDate, this.currentSite.dnsDateTo) <= 1) {
        this.setBlockStyle(dnsBlock, 'error')
      }
      //ssl
      if (this.getNumberOfDays(currientDate, this.currentSite.sslDateTo) > 10) {
        this.setBlockStyle(sslBlock, 'success')
      }
      if (this.getNumberOfDays(currientDate, this.currentSite.sslDateTo) > 1
        && this.getNumberOfDays(currientDate, this.currentSite.sslDateTo) <= 9) {
        this.setBlockStyle(sslBlock, 'warning')
      }
      if (this.getNumberOfDays(currientDate, this.currentSite.sslDateTo) <= 1) {
        this.setBlockStyle(sslBlock, 'error')
      }

    }

  }
}
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5px;
  font-size: 24px;
  border-bottom: 1px solid #0000000f;
  padding-bottom: 10px;
}

.detail__sitename {
    width: fit-content;
    border-radius: 3px;
    font-weight: 600;
}
.detail__sitename::first-letter{
  color: #a52a2a;
}
.status_block {
  font-size: 14px;
  margin-top: 7px;
  display: flex;
  gap: 3px;
}
.status {
    background-color: white;
    width: fit-content;
    height: fit-content;
    padding: 1px 3px;
    border-radius: 3px;
    font-size: 12px;
}
.error {
  background-color: #a52a2a;
  color: #fff;
}
.warning {
  background-color: #ffd300;
}
.success {
  background-color:#006200;
  color: #fff;
}


</style>
