<template>
  <div>
    <div class="sitesList">
      <div class="sitesList_header">
        <font-awesome-icon icon="fa-solid fa-list-ul" />
        <span class="sitesList_header"> Список сайтов:</span>
      </div>
    <div class="emptyList" v-show="SITES_LIST.length==0">
      ¯\_(ツ)_/¯<br>Добавьте сайт для мониторинга.
    </div>
    <ul v-show="SITES_LIST.length>0" class="sitesList_ul">
      <OneSiteInList v-for="site in SITES_LIST" :key="site.id" :oneSiteInList="site"></OneSiteInList>
    </ul>


    <div class="add_container">
      <form @submit.prevent="sendFormAdd()" class="form_add_site">
        <div class="inputBox">
          <select class="protocol">
            <option value="https://" >https</option>
            <option value="http://">http</option>
          </select>
          <input type="text" class="siteNameInput" placeholder="sitename.ru" required/>
        </div>

        <button type="submit" class="addBtn">
          <font-awesome-icon icon="fa-solid fa-plus" /> добавить сайт</button>
      </form>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import OneSiteInList from './OneSiteInList.vue'
export default {
  data() {
    return {
      sitesList: []
    }
  },
  components: {
    OneSiteInList
  },
  computed: {
    ...mapGetters(['SITES_LIST','USER'])
  },
  methods: {
    ...mapMutations(['SET_SITES_LIST','SET_MESSAGE','SET_CURRENT_SITE']),
    ...mapActions(['GET_SITES_LIST','UPDATE_CUR_SITE_STATUS']),

    sendFormAdd() {
      const reg = /^http([s]{0,1})\:\/\/([\wёa-я-]{2,}\.)+[\wёa-я-]{2,}$/i

     const protocol=document.querySelector('.protocol').value

     let siteName=document.querySelector('.siteNameInput').value
     //уберем если надо в конце слеш
     if (siteName[siteName.length - 1]=='/') {
      siteName=siteName.slice(0, -1);
     }
     if(reg.test(protocol+siteName)) {
      //console.log('добавляем');
      this.addSiteRequest(this.USER.token,protocol+siteName)
      document.querySelector('.siteNameInput').classList.remove('error');
     } else {
      document.querySelector('.siteNameInput').classList.add('error');
     }
    },

    addSiteRequest(token,siteName) {
      fetch('https://uptime-vbi.ru/controller/addSite.php?token=' + token+'&sitename='+siteName)
        .then(response => response.json())
        .then(result => {
          if(result.result===true) {
            this.GET_SITES_LIST(token);
            document.querySelector('.siteNameInput').value='';
          } else {
            this.SET_MESSAGE({ show: true, text: result.message })
            document.querySelector('.siteNameInput').value = '';
          }
        })
      },

      updateCurrentSite() {
        if (this.SITES_LIST.length == 0) {
        this.SET_CURRENT_SITE(false)
      } else {
        this.UPDATE_CUR_SITE_STATUS({ siteId: this.SITES_LIST[0].id, token: this.USER.token })
      }
      }

  },
  mounted() {
  },
  watch: {
    USER: function() {
      this.GET_SITES_LIST(this.USER.token);
    },
    SITES_LIST: function() {
      this.updateCurrentSite()
    }
  }





}
</script>

<style scoped>
.sitesList_header {
  display: flex;
  gap: 7px;
}
.sitesList_ul {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #0000000f;
}

.addBtn {
  background: #3a424b18;
  border: 0;
  padding: 7px 0;
  border-radius: 2px;
}

.addBtn:active {
  background: #3a424b25;
}
.addBtn:hover {
  cursor:pointer;
}
.inputBox {
  display: flex;
  gap: 3px;
}
.siteNameInput {

    padding: 5px 3px;
    border-radius: 3px;
    border: 1px solid #0000002e;
    box-sizing: border-box;
}
.error,.error:focus-visible {
  border: 1px solid #a52a2a;
}
.protocol {
  border-radius: 3px;
  border: 1px solid #0000002e;
  box-sizing: border-box;
  padding: 4px 3px;
}
.siteNameInput:focus-visible{
  border: 1px solid #3a424b25;
}
.emptyList {
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  text-align: left;
}
.add_container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: flex-start;
}

.sitesList {
  border-top: 1px solid #0000000f;
  margin-top: 15px;
  padding-top: 15px;
}
.form_add_site {
  display: grid;
  gap: 10px;
}
</style>
