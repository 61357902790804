import { createRouter, createWebHistory } from 'vue-router'
import AuthPage from '@/components/AuthPage'
import Lk from '@/components/Lk'
//import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'auth',
    component: AuthPage
  },
  {
    path: '/lk',
    name: 'lk',
    component: Lk
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
