<template>
  <li class="oneSiteContainer" @click="setCurentSite(oneSite)">
    <span class="sitename" >
        <span class="delete" @click="warning_screen = !warning_screen"><font-awesome-icon icon="fa-solid fa-circle-xmark" /></span>
        {{ oneSite.siteLink }}
      </span>
      <span class="icons_block">
        <span class="status_icon" :class="{ 'info': status.info, 'error': status.error, 'success': status.success, 'redirect':status.redirect}">
          {{ oneSite.status }}
        </span>
        <span class="check_icon" @click="refreshStatus()"><font-awesome-icon icon="fa-solid fa-rotate"  /></span>
      </span>
  </li>
  <li class="warning_block" v-show="warning_screen">
    <span class="warning_text">
      <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
      удалить {{ oneSite.siteLink }}?
    </span>
    <span class="btns_block">
      <span class="btn_yes btn" @click="deleteSite()">да</span>
      <span class="btn_no btn" @click="warning_screen =false">нет</span>
    </span>
  </li>
</template>

<script>
import { mapGetters,mapActions,mapMutations } from 'vuex'
export default {
  data() {
    return {
      oneSite: {},
      status: {
        info: false,
        error: false,
        success: false,
        redirect: false
      },
      warning_screen: false
    }
  },
  props: [
    'oneSiteInList'
  ],
  computed: {
    ...mapGetters(['SITES_LIST', 'USER','MESSAGE', 'CURRENT_SITE'])
  },
  methods: {
    ...mapActions(['GET_SITES_LIST','UPDATE_CUR_SITE_STATUS']),
    ...mapMutations(['SET_MESSAGE', 'SET_CURRENT_SITE']),

    deleteSite(){
      fetch('https://uptime-vbi.ru/controller/deleteSite.php?token=' + this.USER.token + '&id=' + this.oneSite.id)
        .then(response => response.json())
        .then(result => {
          if (result.result === true) {
           this.GET_SITES_LIST(this.USER.token);
          } else {
            this.SET_MESSAGE({ show: true, text: result.message })
          }
        })
    },
    refreshStatus(){
      fetch('https://uptime-vbi.ru/controller/refreshStatus.php?token='+this.USER.token+'&siteId='+this.oneSite.id)
      .then(response=>response.json())
      .then(result=>{
        if (result.result==true) {
          this.oneSite.status = result.data.http.httpCode;
          this.updateHTTPStatusBlock(this.oneSite.status)
          //console.log(result)
        } else {
          this.SET_MESSAGE({ show: true, text: result.message })
        }
      })
    },
    updateHTTPStatusBlock(newStatus) {
      if (newStatus==0) {
        this.status.info = false;
        this.status.success = false;
        this.status.error = true;
        this.status.redirect=false;
      }
      if (newStatus >= 100 && newStatus <= 199) {
        this.status.info = true;
        this.status.success = false;
        this.status.error = false;
        this.status.redirect = false;
      }
      if (newStatus >= 200 && newStatus <= 299) {
        this.status.info = false;
        this.status.success = true;
        this.status.error = false;
        this.status.redirect = false;
      }
      if (newStatus >= 300 && newStatus <= 399) {
        this.status.info = false;
        this.status.success = false;
        this.status.error = false;
        this.status.redirect = true;
      }
      if (newStatus >= 400 ) {
        this.status.info = false;
        this.status.success = false;
        this.status.error = true;
        this.status.redirect = false;
      }

    },
    //при клике на название сайта
    setCurentSite(oneSite) {
      this.UPDATE_CUR_SITE_STATUS({ siteId: oneSite.id, token: this.USER.token })
    }


  },
  mounted() {
    this.oneSite = this.oneSiteInList
    this.refreshStatus();
  }
}
</script>

<style scoped>
.oneSiteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 3px;
}
.oneSiteContainer:hover {
  background-color: #fff;
  border-radius: 3px;
  cursor:pointer;
}
.sitename {
  display: flex;
  align-items: center;
  gap: 7px;
}
.icons_block {
  min-width: 70px;
  display: flex;
  justify-content: flex-end;
  gap: 7px;
}
.status_icon {
  width: 27px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  padding: 1px 3px;
  border-radius: 3px;
}
.check_icon:hover{
  cursor: pointer
}
.error {
  background-color: #a52a2a;
  color: #fff;
}
.redirect {
  background-color: #ffd300;
}
.success {
  background-color:#006200;
  color: #fff;
}
.info {
  background-color: #fff;
}
.warning_block {
  background-color: #a52a2a;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 7px;
  margin: 7px 0;
}
.warning_text {
  color: #fff;
}
.btns_block {
  display: flex;
  gap: 50px;
  margin-top: 10px;
}
.btn_yes{
  background-color: #ffd300;
}
.btn_no {
  background-color: #006200;
  color:#fff;
}
.btn {
  padding: 3px 7px;
  border-radius: 3px;
}
.btn:hover {
  cursor: pointer;
}
</style>
