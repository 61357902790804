<template>
  <div class="detail">
    <div class="month_header">
      {{ dateTime }}
    </div>
    <div class="month_container">
      <div class="day day__header">пн</div>
      <div class="day day__header">вт</div>
      <div class="day day__header">ср</div>
      <div class="day day__header">чт</div>
      <div class="day day__header">пт</div>
      <div class="day day__header">сб</div>
      <div class="day day__header">вс</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'


export default {
  data() {
    return {
      days: [],
      currientDate: '',
      yearBeforeDate: '',
      moment: '',
      dateTime: '',
      monthData: '',
      daysInMonth: ''
    }
  },
  computed: {
   ...mapGetters(['CURRENT_SITE', 'USER','DAY'])
  },
  mounted() {
    this.currientDate=new Date
    this.SET_DAY(this.currientDate)
    //чтобы не затирало предыдущее
    let calculateData = new Date(this.currientDate);
    this.yearBeforeDate = calculateData.setMonth(calculateData.getMonth()-12)
    moment.locale('ru')
    this.dateTime = moment(this.DAY).format("MMMM YYYY");

    //рисуем табличку
    this.drawMonthTable(this.currientDate);


  },
  methods: {
    ...mapMutations(['SET_DAY','SET_MESSAGE']),
    drawMonthTable(day) {
      //получаем количество дней в месяце
      this.daysInMonth= 33 - new Date(day.getFullYear(), day.getMonth(), 33).getDate();
      //день недели
      let index = (new Date(day.getFullYear(), day.getMonth(), 1)).getDay()
      let month=document.querySelector('.month_container');

      for (let i=0;i<index-1;i++){
        month.innerHTML+='<div class="day"></div>';
      }

      for(let i=1;i<= this.daysInMonth;i++) {
        month.innerHTML += '<div class="day day_'+i+'" data-day_number="'+i+'"><span class="day__status day__status_'+i+'"></span><span>'+i+'</span></div>';
        document.querySelector('[data-day_number="' + i+ '"]').classList.add('day');
      }
      for (let i = 1; i <= this.daysInMonth; i++) {
        document.querySelector('.day_' + i).addEventListener('click', () => { this.setNewDay(i) })
      }

      document.querySelector('.day[data-day_number="'+day.getDate()+'"]').classList.add('dayChecked');
    },
    setBlockStyle(block, style) {
      block.classList.remove('success')
      block.classList.remove('error')
      block.classList.remove('warning')
      block.classList.add(style)
    },
    setNewDay(dayNumber) {
      const newDay=new Date(this.DAY.getFullYear(),this.DAY.getMonth(),dayNumber)
      this.SET_DAY(newDay)
      for (let i = 1; i <= this.daysInMonth; i++) {
        document.querySelector('.day_' + i).classList.remove('dayChecked');
      }
      document.querySelector('.day_' + this.DAY.getDate()).classList.add('dayChecked');
      //console.log(newDay)
    },
    setTableSlyle(monthData) {
      //почистим сперва
      for (let i = 1; i <= this.daysInMonth; i++) {
        let block = document.querySelector('.day__status_' + i)
        block.classList.remove('success')
        block.classList.remove('error')
        block.classList.remove('warning')
      }
      monthData.forEach((day)=>{

        let dayNumber=(new Date(day[0])).getDate();
        let statusBlock = document.querySelector('.day__status_' + dayNumber)
        if (day[1] >= 200 && day[1] <= 299) {
          this.setBlockStyle(statusBlock, 'success')
        }
        if (day[1] >= 300 && day[1] <= 399) {
          this.setBlockStyle(statusBlock, 'warning')
        }
        if (day[1] >= 400 || day[1] == 0) {
          this.setBlockStyle(statusBlock, 'error')
        }

      })
    },
    getMonthData(day) {
      let startDate =day.getFullYear()+'-'+ (day.getMonth()+1) +'-01'
      this.daysInMonth = 33 - new Date(day.getFullYear(), day.getMonth(), 33).getDate();
      let stopDate = day.getFullYear() + '-' + (day.getMonth()+1) + '-'+this.daysInMonth

      fetch('https://uptime-vbi.ru/controller/getMonthData.php?stopDate='+ stopDate+'&startDate='+ startDate +'&token=' + this.USER.token + '&siteId=' + this.CURRENT_SITE.id)
        .then(response => response.json())
        .then(result => {
          if (result.result == true) {
            this.monthData=result.data
            this.setTableSlyle(this.monthData)
          } else {
            this.SET_MESSAGE({ show: true, text: result.message })
             this.setTableSlyle([])
          }
        })
    },


  },
  watch: {
    CURRENT_SITE: function () {
      let day=this.DAY
      this.getMonthData(day)
    }
  }
}
</script>

<style >
.detail {
    margin-top: 10px;
}
.month_header {
    text-align: left;
    background-color: #fff;
    width: fit-content;
    padding: 3px 7px;
    border-radius: 3px;
}
.month_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1px;
    margin: 10px;
}
.day__header {
    font-size: 15px;
}
.day {
  display: flex;
  border: 1px solid #0000000f;
  background: #fff;
  padding: 10px 10px;
  border-radius: 4px;
  justify-content: flex-end;
  align-items: baseline;
  gap: 5px;
}
.day[data-day_number]:hover {
  border: 1px solid #000000a8;
  cursor: pointer;
}

.dayChecked {
  border-color: #000;
}
.day__status {
  height: 10px;
  width: 10px;
  border: 1px solid #0000000f;
  border-radius: 3px;
}
.noDetailContainer {
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  background: #ffd300;
  width: 24px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #0000000f;
}

.error {
  background-color: #a52a2a;
}
.warning {
  background-color: #ffd300;
}
.success {
  background-color:#006200;
  color: #fff;
}

</style>
